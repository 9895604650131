import { getNextRoute } from '@helpers/routes'

export const getPath = (offer, suffix) => {
  const offerSlug = offer?.slug
  const routeParams = {
    country: offer?.country?.slug.toLowerCase(),
    brand: offer?.brand?.slug,
    ccg: offer?.closedConsumerGroup
  }

  return (
    getNextRoute('brand.ccg', routeParams) + getUrlParams(suffix, offerSlug)
  )
}

export const getElement = (offer) => (offer?.expired ? 'div' : 'a')

export const getUrlParams = (suffix, offerSlug) =>
  suffix ? `${suffix}&offer=${offerSlug}` : `?offer=${offerSlug}`
