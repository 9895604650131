import { ACTIVE_CONSUMER_GROUPS } from '@constants/consumer_groups'

export const getActiveConsumerGroupNames = () =>
  ACTIVE_CONSUMER_GROUPS.map((ccg) => ccg.label)

export const getActiveConsumerGroupIds = () =>
  ACTIVE_CONSUMER_GROUPS.map((ccg) => ccg.id)

export const getConsumerGroupNameFromSlug = (slug) => {
  const ccg = ACTIVE_CONSUMER_GROUPS.find((ccg) => ccg.id === slug)
  return ccg.label
}

export const getCCGParam = (ccg) => {
  if (!ccg) return null

  if (Array.isArray(ccg)) return ccg[0]

  return ccg
}

export const isValidConsumerGroupId = (id) => {
  return getActiveConsumerGroupIds().includes(id)
}

export const getCCGFromParams = (params) => {
  const ccgParam = getCCGParam(params?.ccg)

  if (isValidConsumerGroupId(ccgParam)) return ccgParam

  return ''
}
