import { useRouter } from 'next/router'

import { getCCGFromParams } from '@helpers/consumer_groups'

export const useCCG = () => {
  const router = useRouter()
  const ccg = getCCGFromParams(router?.query)

  return ccg
}
