import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'

import * as styles from './styles'

const OfferTileLogo = ({ offer }) => {
  if (!offer?.brand?.logo) return null

  return (
    <div
      css={styles.container}
      data-testid='offer-logo-container'
    >
      <SafeImage
        alt={offer?.brand?.name}
        src={offer?.brand?.logo}
        width={100}
        height={100}
      />
    </div>
  )
}

OfferTileLogo.propTypes = {
  offer: PropTypes.shape({
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default OfferTileLogo
