import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'

import { getAltText } from './helpers'
import * as styles from './styles'

const OfferTileImage = ({ offer, priority }) => {
  const renderImage = () => {
    if (!offer?.defaultImage) return null

    return (
      <SafeImage
        css={styles.image}
        src={offer?.defaultImage}
        data-testid='offer-image'
        alt={getAltText(offer)}
        fill
        style={{ objectFit: 'cover' }}
        priority={priority}
      />
    )
  }

  return (
    <div
      css={styles.container}
      data-testid='offer-image-container'
    >
      {renderImage()}
    </div>
  )
}

OfferTileImage.propTypes = {
  offer: PropTypes.shape({
    defaultImage: PropTypes.string,
    defaultImageSmall: PropTypes.string,
    expired: PropTypes.bool.isRequired,
    brand: PropTypes.shape({
      name: PropTypes.string
    }),
    activeFlags: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            live: PropTypes.bool,
            flag: PropTypes.shape({
              name: PropTypes.string
            })
          })
        })
      )
    })
  }).isRequired,
  priority: PropTypes.bool
}

OfferTileImage.defaultProps = {
  priority: false
}

export default OfferTileImage
