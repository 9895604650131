import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const icon = css`
  position: absolute;
  top: 0;
  right: 20px;
  padding-top: 5px;
  padding-bottom: 8px;
  border-radius: 0px 0px 20px 20px;
  background-color: ${Colours('grey', '100')};
  display: flex;
  justify-content: center;
  align-items: center;
`
