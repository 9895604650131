import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background-color: ${Colours('liquorice', '200')};
  height: 200px;
`

export const image = css`
  width: 100%;
  height: 100%;
`

export const imageOverlay = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
`
