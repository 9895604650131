import PropTypes from 'prop-types'

import { getPath, getElement } from './helpers'
import * as styles from './styles'

const OfferTileLink = ({ children, offer, onClick, pathSuffix }) => {
  const Element = getElement(offer)

  return (
    <Element
      css={styles.link}
      href={getPath(offer, pathSuffix)}
      onClick={onClick}
    >
      {children}
    </Element>
  )
}

OfferTileLink.propTypes = {
  children: PropTypes.any.isRequired,
  offer: PropTypes.shape({
    brand: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired,
    country: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired,
    expired: PropTypes.bool.isRequired,
    slug: PropTypes.string.isRequired,
    closedConsumerGroup: PropTypes.string.isRequired
  }).isRequired,
  pathSuffix: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

/* istanbul ignore next */
OfferTileLink.defaultProps = {
  onClick: () => {}
}

export default OfferTileLink
