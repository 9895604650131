import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import { isExpired } from './helpers'
import * as styles from './styles'

const OfferTileContainer = forwardRef(function OfferTileContainer(props, ref) {
  return (
    <article
      css={[styles.container, !isExpired(props) && styles.clickable]}
      itemProp='offers'
      itemScope=''
      itemType='http://schema.org/Offer'
      ref={ref}
    >
      {props?.children}
    </article>
  )
})

OfferTileContainer.propTypes = {
  children: PropTypes.any.isRequired,
  offer: PropTypes.shape({
    expired: PropTypes.bool.isRequired
  }).isRequired
}

export default OfferTileContainer
