import { NO_INDEX } from '@constants/metadata'
import { withAssetPrefix } from '@helpers/assets'

import { DEFAULT_TITLE } from './constants'

export const getRobotsContent = (robots = []) => {
  if (shouldAddNoIndex(robots)) robots.push(NO_INDEX)
  return robots.join(', ')
}

export const shouldAddNoIndex = (robots) => {
  if (robots.includes(NO_INDEX)) return false

  return process.env.NEXT_PUBLIC_APP_ENV === 'staging'
}

export const getTitle = (title) => title || DEFAULT_TITLE

export const formatLdData = (data) => {
  const graphData = data.map((item) => {
    if (item?.type === 'Organisation') return getLdOrganisationData()

    if (item?.type === 'BreadcrumbList') return getLdBreadcrumbListData(item)

    if (item?.type === 'FAQPage') return getLdFaqData(item)

    if (item?.type === 'Product') return getLdProductData(item)

    return null
  })

  return {
    '@context': 'https://schema.org',
    '@graph': graphData
  }
}

export const getLdOrganisationData = () => {
  return {
    '@type': 'Organization',
    url: 'https://www.beansid.com',
    logo: withAssetPrefix('images/logo.svg'),
    name: 'Logo'
  }
}

export const getLdBreadcrumbListData = (data) => {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: data?.list.map((breadcrumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb?.breadcrumbName,
        item: breadcrumb?.breadcrumbUrl
      }
    }),
    name: 'Breadcrumbs'
  }
}

export const getLdFaqData = (data) => {
  return {
    '@type': 'FAQPage',
    mainEntity: data?.list.map((faq) => {
      return {
        '@type': 'Question',
        name: faq?.faqQuestion,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq?.faqAnswer
        }
      }
    }),
    name: 'FAQs'
  }
}

export const getLdProductData = (data) => {
  return data?.list.map((discount) => {
    return {
      '@type': 'Product',
      name: discount.discountName,
      brand: {
        '@type': 'Brand',
        name: discount.brandName
      },
      description: discount.discountDescription,
      image: discount.discountImage,
      offers: {
        '@type': 'Offer',
        discount: discount.discountAmount
      }
    }
  })
}
