import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  z-index: 2;
  padding: 10px;
  border-radius: 10px;
  background-color: ${Colours('white', '300')};
  position: relative;
  margin: -90px auto 0 10px;
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }
`
