const features = {
  algolia_sorting: false, // FEATURE_TOGGLE 2024/11/01 @rapidash
  ccg_page_description: true, // FEATURE_TOGGLE 2024/11/01 @rapidash
  brand_page_aggregate_offers: true, // FEATURE_TOGGLE 2024/11/01 @rapidash
  brand_page_related_offers: true, // FEATURE_TOGGLE 2024/11/01 @rapidash
  ccg_category_page: true, // FEATURE_TOGGLE 2024/11/01 @rapidash
  brand_page_breadcrumb: true // FEATURE_TOGGLE 2024/11/01 @rapidash
}

export default features
