export const IMPRESSION_LOAD_STORE_NAME = 'impression_load'

export const IMPRESSION_VIEW_STORE_NAME = 'impression_view'

export const IMPRESSION_EVENT_NAME = 'stb_impression_event'

export const IMPRESSION_LOAD_SCHEMA_URI =
  'iglu:com.studentbeans/impression_load/jsonschema/1-0-0'

export const IMPRESSION_VIEW_SCHEMA_URI =
  'iglu:com.studentbeans/impression_view/jsonschema/1-0-0'

export const BATCH_EVENT_LIMIT = 20

export const IMPRESSION_CLICK_SCHEMA_URI =
  'iglu:com.studentbeans/impression_click/jsonschema/1-0-0'
