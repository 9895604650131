import featuresToggles from '@constants/feature_toggles'

class FeatureToggle {
  #featureToggles = {}

  constructor(features) {
    this.#featureToggles = features
  }

  load(features, clean = false) {
    if (clean) this.#featureToggles = {}

    const updatedFeatures = {
      ...this.#featureToggles,
      ...features
    }
    this.#featureToggles = updatedFeatures
  }

  isEnabled(feature) {
    return !!this.#featureToggles[feature]
  }
}

const featureToggle = new FeatureToggle(featuresToggles)
export default featureToggle
