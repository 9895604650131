import { hasImpressionTrackingSupport } from '@helpers/impression_tracking'

export const shouldObserve = (ref, hasBeenViewed, isSnowplowSetup) => {
  return !!(
    ref?.current &&
    !hasBeenViewed &&
    hasImpressionTrackingSupport() &&
    isSnowplowSetup
  )
}
