import {
  allObjValuesAreDefined,
  getValueOrNull
} from '@helpers/impression_tracking'

export const getImpressionLoadData = (offer, impressionContent) => {
  const required = getRequiredImpressionLoadData(offer, impressionContent)
  if (!required) return null

  const optional = getOptionalImpressionLoadData(impressionContent)
  return { ...required, ...optional }
}

export const getOptionalImpressionLoadData = (impressionContent) => {
  return {
    group_version_id: getValueOrNull(impressionContent?.groupVersionId)
  }
}

export const getRequiredImpressionLoadData = (offer, impressionContent) => {
  const required = {
    group_position: impressionContent?.groupPosition,
    group_type: impressionContent?.groupType,
    group_id: impressionContent?.groupId,
    content_position: impressionContent?.contentPosition,
    content_type: offer?.impressionContent?.type,
    content_id: offer?.impressionContent?.id,
    content_version_id: offer?.impressionContent?.version
  }
  if (!allObjValuesAreDefined(required)) return null

  return required
}
