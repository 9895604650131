import { gql } from '@apollo/client'

export const OFFER_ISSUANCE_TILE_CCG_ICON_FRAGMENT = gql`
  fragment OfferIssuanceTileCCGIcon on Offer {
    closedConsumerGroup
  }
`

export const ICON_WIDTH = '45px'
export const ICON_HEIGHT = '50px'
