import Head from 'next/head'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { withAssetPrefix } from '@helpers/assets'

import { BEANS_ID_FAVICON, DEFAULT_TITLE } from './constants'
import { getRobotsContent, getTitle, formatLdData } from './helpers'

const Metadata = ({
  title,
  description,
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogUrl,
  ogImage,
  robots,
  ldData
}) => {
  const renderTitle = () => {
    return <title key='title'>{getTitle(title)}</title>
  }

  const renderDescription = () => {
    if (!description) return null

    return (
      <meta
        content={description}
        name='description'
        key='description'
      />
    )
  }

  const renderCanonicalUrl = () => {
    if (!canonicalUrl) return null

    return (
      <link
        href={canonicalUrl}
        rel='canonical'
        key='canonical'
      />
    )
  }

  const renderOgTitle = () => {
    if (!ogTitle) return null

    return (
      <meta
        content={ogTitle}
        property='og:title'
        key='og:title'
      />
    )
  }

  const renderOgDescription = () => {
    if (!ogDescription) return null

    return (
      <meta
        content={ogDescription}
        property='og:description'
        key='og:description'
      />
    )
  }

  const renderOgUrl = () => {
    if (!ogUrl) return null

    return (
      <meta
        content={ogUrl}
        property='og:url'
        key='og:url'
      />
    )
  }

  const renderOgImage = () => {
    if (!ogImage) return null

    return (
      <meta
        content={ogImage}
        property='og:image'
        key='og:image'
      />
    )
  }

  const renderRobots = () => {
    const robotsContent = getRobotsContent(robots)
    if (!robotsContent) return null

    return (
      <meta
        content={robotsContent}
        name='robots'
        key='robots'
      />
    )
  }

  const renderLd = () => {
    if (ldData.length === 0) return null

    const formattedData = formatLdData(ldData)
    return (
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(formattedData) }}
      />
    )
  }

  return (
    <Fragment>
      <Head>
        {renderTitle()}
        {renderDescription()}
        {renderCanonicalUrl()}
        {renderOgTitle()}
        {renderOgDescription()}
        {renderOgUrl()}
        {renderOgImage()}
        {renderRobots()}
        <link
          rel='shortcut icon'
          href={withAssetPrefix(BEANS_ID_FAVICON)}
        />
      </Head>
      {renderLd()}
    </Fragment>
  )
}

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  robots: PropTypes.arrayOf(PropTypes.string),
  ldData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        'Organisation',
        'BreadcrumbList',
        'FAQPage',
        'Product'
      ]).isRequired,
      list: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            breadcrumbName: PropTypes.string,
            breadcrumbUrl: PropTypes.string
          }),
          PropTypes.shape({
            faqQuestion: PropTypes.string,
            faqAnswer: PropTypes.string
          }),
          PropTypes.shape({
            discountName: PropTypes.string,
            discountDescription: PropTypes.string,
            discountImage: PropTypes.string,
            discountAmount: PropTypes.string,
            brandName: PropTypes.string
          })
        ])
      )
    })
  )
}

Metadata.defaultProps = {
  title: DEFAULT_TITLE,
  description: '',
  canonicalUrl: '',
  ogTitle: '',
  ogDescription: '',
  ogUrl: '',
  ogImage: '',
  robots: [],
  ldData: []
}

export default Metadata
