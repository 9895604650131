import { useEffect, useState } from 'react'

import { SNOWPLOW_IS_SETUP } from '@constants/snowplow'

export const useIsSnowplowSetup = () => {
  const [isSnowplowSetup, setIsSnowplowSetup] = useState(false)

  const handleSnowplowSetup = () => {
    setIsSnowplowSetup(true)
  }

  useEffect(() => {
    document.addEventListener(SNOWPLOW_IS_SETUP, handleSnowplowSetup)

    return () => {
      document.removeEventListener(SNOWPLOW_IS_SETUP, handleSnowplowSetup)
    }
  }, [])

  return { isSnowplowSetup }
}
