import PropTypes from 'prop-types'

import { isValidConsumerGroupId } from '@helpers/consumer_groups'

import { ICON_HEIGHT, ICON_WIDTH } from './constants'
import { getIcon } from './helpers'
import * as styles from './styles'

const OfferIssuanceTileCCGIcon = ({ offer }) => {
  const ccg = offer?.closedConsumerGroup
  if (!isValidConsumerGroupId(ccg)) return null

  const Icon = getIcon(ccg)
  return (
    <div
      role='presentation'
      css={styles.icon}
    >
      <Icon
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
      />
    </div>
  )
}

OfferIssuanceTileCCGIcon.propTypes = {
  offer: PropTypes.shape({
    closedConsumerGroup: PropTypes.string.isRequired
  }).isRequired
}

export default OfferIssuanceTileCCGIcon
