import root from 'window-or-global'

import {
  setup,
  trackPageView as tptTrackPageView,
  trackStructEvent as tptTrackStructEvent,
  trackSelfDescribingEvent as tptTrackSelfDescribingEvent,
  setUserId
} from '@thebeansgroup/third_party_tracking/snowplow'

import { SNOWPLOW_IS_SETUP } from '@constants/snowplow'

export const setupSnowplow = (userId) => {
  setup(
    process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_ENDPOINT,
    process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID,
    process.env.NEXT_PUBLIC_SNOWPLOW_COOKIE_DOMAIN
  )

  setUserId(userId)
  document.dispatchEvent(new root.CustomEvent(SNOWPLOW_IS_SETUP))
}

export const trackPageView = (title = null, customContexts = []) => {
  tptTrackPageView({ title, context: customContexts })
}

export const trackStructEvent = (category, action, label, property, value) => {
  tptTrackStructEvent({
    category,
    action,
    label,
    property,
    value
  })
}

export const trackUnstructEvent = (schema, data, contexts) => {
  tptTrackSelfDescribingEvent({
    event: {
      schema,
      data
    },
    context: contexts
  })
}
