const features = {
  algolia_sorting: false,
  ccg_page_description: true,
  brand_page_aggregate_offers: true,
  brand_page_related_offers: true,
  ccg_category_page: true,
  brand_page_breadcrumb: true
}

export default features
