import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Fonts from '@thebeansgroup/ui_style_helpers/fonts'

export const container = css`
  width: 100%;
  margin: 25px 0 16px 0;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  padding-bottom: 5px;
`

export const title = css`
  font-size: 22px;
  line-height: 24.75px;
  max-height: 49.5px;
  text-align: left;

  margin-top: 0;
  overflow: hidden;

  color: ${Colours('grey', '500')};
  font-family: ${Fonts('gibsonSemiBold')};
`

export const onlineAt = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colours('grey', '500')};
  line-height: 16px;
  font-family: ${Fonts('gibson')};
  text-align: left;
  position: absolute;
  bottom: 0;

  &:hover,
  &:focus {
    color: ${Colours('grey', '300')};

    span {
      color: ${Colours('huckleberry', '100')};
    }
  }
`

export const brandName = css`
  font-weight: 600;
  color: ${Colours('huckleberry', '500')};
`

export const placeholderContainer = css`
  margin: 16px 0;
`
