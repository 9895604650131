import { css } from '@emotion/react'

export const container = css`
  position: relative;
  display: block;
  margin: 16px 0;
  border-radius: 5px;
  cursor: auto;
  transition: transform 500ms cubic-bezier(0.495, 0.005, 0.02, 1.065);

  &:hover {
    transform: translateY(-10px);
  }
`

export const clickable = css`
  cursor: pointer;
`
