import PropTypes from 'prop-types'
import { useRef } from 'react'

import { useCountryCode } from '@root/src/hooks/use_country_code'

import OfferIssuanceTileCCGIcon from '@components/offer_issuance_tile_ccg_icon'
import OfferTileContainer from '@components/offer_tile_container'
import OfferTileImage from '@components/offer_tile_image'
import OfferTileLink from '@components/offer_tile_link'
import OfferTileLogo from '@components/offer_tile_logo'
import {
  getImpressionRef,
  reportErrorHandler,
  trackImpressionClick
} from '@helpers/impression_tracking'
import { getNextRoute } from '@helpers/routes'
import useImpressionTracking from '@hooks/use_impression_tracking'

import { getImpressionLoadData } from './helpers'
import * as styles from './styles'

const OfferTile = ({
  offer,
  pathSuffix,
  impressionContent,
  priority,
  showCCGIcon
}) => {
  const country = useCountryCode()
  const offerContainerRef = useRef(null)

  const impressionLoadData = getImpressionLoadData(offer, impressionContent)

  const { impressionId } = useImpressionTracking(
    impressionLoadData,
    getImpressionRef(offerContainerRef, !!impressionLoadData),
    reportErrorHandler
  )

  const renderCCGIcon = () => {
    if (!showCCGIcon) return null

    return <OfferIssuanceTileCCGIcon offer={offer} />
  }

  const handleClick = () => {
    trackImpressionClick(impressionId)
  }

  return (
    <OfferTileContainer
      offer={offer}
      ref={offerContainerRef}
    >
      <OfferTileLink
        offer={offer}
        pathSuffix={pathSuffix}
        onClick={handleClick}
      >
        <OfferTileImage
          offer={offer}
          priority={priority}
        />
        <OfferTileLogo offer={offer} />
        {renderCCGIcon()}
        <div
          css={styles.container}
          data-testid='offer-tile-title-container'
        >
          <h4
            title={offer?.title}
            itemProp='name'
            css={styles.title}
          >
            {offer?.title}
          </h4>
        </div>
      </OfferTileLink>
      <a
        css={styles.onlineAt}
        href={getNextRoute('brand', { country, brand: offer?.brand?.slug })}
      >
        Online at <span css={styles.brandName}>{offer?.brand?.name}</span>
      </a>
    </OfferTileContainer>
  )
}

OfferTile.propTypes = {
  offer: PropTypes.object.isRequired,
  pathSuffix: PropTypes.string,
  scrollPosition: PropTypes.any,
  impressionContent: PropTypes.shape({
    contentPosition: PropTypes.number,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  priority: PropTypes.bool,
  showCCGIcon: PropTypes.bool
}

OfferTile.defaultProps = {
  scrollPosition: null,
  priority: false,
  pathSuffix: '',
  impressionContent: null,
  showCCGIcon: false
}

export default OfferTile
